/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// fonts
import '@fontsource/public-sans';
import "@fontsource/dm-sans";
import "@fontsource/roboto-condensed";
import "@fontsource/bebas-neue";

import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleOAuthProvider clientId={process.env.GATSBY_GOOGLE_CLIENT_ID}>
      {element}
    </GoogleOAuthProvider>
  )
}
